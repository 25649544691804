<template>
	<div class="min-h-screen relative">
		<div class="__setup-center px-12 md-down:px-4 pb-30 md:w-1/2">
			<div
				class="
					__setup-center__header
					pt-10
					pb-4
					flex
					justify-between
					gap-x-20 gap-y-8
					items-center
					flex-wrap
					xl:flex-nowrap
				"
			>
				<div class="flex items-center w-full md-down:justify-between">
					<div
						class="
							__record__title
							text-neutral-500
							font-semibold
							whitespace-nowrap
							flex flex-nowrap
							items-center
							text-4xl
							cursor-pointer
						"
						@click="$router.back()"
					>
						<svg
							class="mr-2"
							fill="none"
							height="28"
							viewBox="0 0 28 28"
							width="28"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M17.5 7L10.5 14L17.5 21"
								stroke="#94A3B8"
								stroke-linecap="round"
								stroke-linejoin="round"
								stroke-width="2"
							/>
						</svg>
						Tạo mục tiêu chung
					</div>
				</div>
				<div class="search-input w-full xl:max-w-lg">
					<div class="input-group">
						<input
							v-model="searchQuery"
							class="bg-white input-with-icon"
							placeholder="Tìm theo mục tiêu"
							type="text"
							@keyup="searching"
							@keypress.enter="search"
						/>
						<div class="input-icon absolute top-1/2">
							<DaIcon
								class="h-4 w-4 text-neutral-400"
								name="das--search"
							/>
						</div>
					</div>
				</div>
			</div>
			<div class="mt-6">
				<div class="mt-14 text-neutral-700 font-semibold">
					Chọn các mục tiêu đang có trong Ngân hàng mục tiêu mà bạn
					muốn thêm vào mục tiêu chung
				</div>
				<div class="mt-6">
					<div class="font-semibold text-neutral-600 mb-2">
						Lĩnh vực*
					</div>
					<div class="select-tabs mb-6">
						<Multiselect
							deselect-label="Đã chọn"
							track-by="name"
							:options="
								skillsData?.map((s) => {
									return {
										nameSearch: toEnglish(s?.name),
										...s,
									};
								})
							"
							v-model="skillSelected"
							:searchable="true"
							label="nameSearch"
							:allow-empty="false"
							selectLabel=""
							placeholder="Chọn lĩnh vực"
						>
							<template #noResult>
								Không tìm thấy kết quả
							</template>
							<template v-slot:option="props">
								<div class="flex flex-nowrap items-center">
									<div>
										{{ props.option.name }}
									</div>
								</div>
							</template>
							<template v-slot:singleLabel="{ option }">
								<div
									class="
										flex flex-nowrap
										items-center
										font-medium
									"
								>
									<div>{{ option.name }}</div>
								</div>
							</template>
						</Multiselect>
					</div>
				</div>
				<div class="mt-8">
					<div v-if="goals && goals.length > 0">
						<div
							v-if="
								goals.length > 1 ||
								goals?.[0]?.id !== 'no_have_group'
							"
						>
							<div
								v-for="(group, index) in goals"
								:key="group.id"
								class="mb-8"
							>
								<GroupDetailFull
									:data="group"
									:index="index"
									@refresh="fetchPlans"
									:createGoal="true"
									@select="(goal) => onSelect(goal)"
									:isSelected="isSelected"
								/>
							</div>
						</div>
						<div v-else>
							<div
								v-for="(group, index) in goals?.[0]
									?.goals_group_by_skill"
								:key="group.id"
							>
								<GoalListFull
									:data="group"
									:index="index"
									:student-id="Number(studentId)"
									class="mb-6"
									type="library"
									:createGoal="true"
									@select="(goal) => onSelect(goal)"
									:isSelected="isSelected"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			:class="showModal ? 'right-0' : '-right-full md:right-0'"
			class="
				bg-white
				md-down:px-8 md-down:py-16
				p-12
				fixed
				top-0
				h-screen
				w-screen
				md:w-1/2
				overflow-y-auto
				flex flex-col
				justify-between
				duration-300
			"
		>
			<div class="relative">
				<div class="">
					<div class="font-semibold text-neutral-600">
						Danh sách mục tiêu đã chọn
					</div>
					<div style="color: #94a3b8">
						Danh sách mục tiêu đã chọn sẽ được thêm vào trong mục
						tiêu chung
					</div>
				</div>
				<div class="mt-8 mb-20">
					<div class="shadow-sm sm:rounded-t-2xl overflow-hidden">
						<div class="__record__table overflow-x-auto scroll-bar">
							<div
								class="
									overflow-hidden
									sm:rounded-t-2xl
									w-fit
									min-w-full
								"
							>
								<table
									class="min-w-full divide-y text-neutral-200"
								>
									<thead class="bg-neutral-100 h-16">
										<tr>
											<th
												class="
													px-6
													py-3
													text-left text-xs
													font-semibold
													text-neutral-600
													uppercase
													tracking-wider
													whitespace-nowrap
												"
												scope="col"
											>
												MỤC TIÊU CHUNG
											</th>
											<th
												class="
													px-6
													py-3
													text-left text-xs
													font-semibold
													text-neutral-600
													uppercase
													tracking-wider
													whitespace-nowrap
												"
												scope="col"
											>
												LĨNH VỰC
											</th>
										</tr>
									</thead>
									<tbody>
										<tr
											class="
												text-neutral-700
												hover:bg-orange-50
												cursor-pointer
												bg-white
											"
										>
											<td
												class="
													px-6
													py-4
													text-neutral-700
												"
											>
												<div class="input-group">
													<input
														type="text"
														placeholder="Điền tên mục tiêu chung"
														class="bg-white w-full"
														v-model="name"
														:class="
															errorName
																? 'input&#45;&#45;error'
																: ''
														"
													/>
													<div
														v-if="errorName"
														class="
															error
															text-sienna-600
															text-sm
															w-full
															mt-2
														"
													>
														{{ errorName }}
													</div>
												</div>
											</td>
											<td
												class="
													px-6
													py-4
													text-neutral-700
													w-max
													whitespace-nowrap
												"
											>
												{{ skillSelected.name }}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
					<div v-if="selected && selected.length > 0" class="pl-16">
						<div>
							<div class="mb-10">
								<div
									class="
										shadow-sm
										sm:rounded-b-2xl
										overflow-hidden
									"
								>
									<div
										class="
											__record__table
											overflow-x-auto
											scroll-bar
										"
									>
										<div
											class="
												overflow-hidden
												sm:rounded-b-2xl
												w-fit
												min-w-full
											"
										>
											<div
												class="
													min-w-full
													divide-y
													text-neutral-200
												"
											>
												<div
													class="bg-neutral-100 h-16"
												>
													<div
														class="
															flex
															items-center
															h-full
														"
													>
														<div class="w-16"></div>
														<div
															class="
																px-6
																py-3
																text-left
																text-xs
																font-semibold
																text-neutral-600
																uppercase
																tracking-wider
																w-16
																whitespace-nowrap
															"
															scope="col"
														>
															STT
														</div>
														<div class="w-16"></div>
														<div
															class="
																px-6
																py-3
																text-left
																text-xs
																font-semibold
																text-neutral-600
																uppercase
																tracking-wider
																whitespace-nowrap
															"
															scope="col"
															style="
																width: calc(
																	100% - 16rem
																);
															"
														>
															Nội dung
														</div>
														<div class="w-16"></div>
													</div>
												</div>

												<draggable
													:list="selected"
													@change="log"
												>
													<transition-group>
														<div
															v-for="(
																goal, i
															) in selected"
															:key="i"
														>
															<div
																:class="
																	i % 2 === 0
																		? 'bg-white'
																		: 'bg-neutral-50'
																"
																class="
																	text-neutral-700
																	hover:bg-orange-50
																	cursor-pointer
																	flex
																	items-center
																"
															>
																<div
																	class="
																		px-6
																		py-4
																		w-16
																	"
																>
																	<svg
																		width="17"
																		height="17"
																		viewBox="0 0 17 17"
																		fill="none"
																		xmlns="http://www.w3.org/2000/svg"
																	>
																		<path
																			d="M17 16.9999L8 16.9999C6.93913 16.9999 5.92172 16.5785 5.17157 15.8284C4.42143 15.0782 4 14.0608 4 12.9999L4 3.91394L1.5 6.41394L0.0860007 4.99994L5 0.085938L9.914 4.99994L8.5 6.41394L6 3.91394L6 12.9999C6 13.5304 6.21071 14.0391 6.58579 14.4142C6.96086 14.7892 7.46957 14.9999 8 14.9999L17 14.9999L17 16.9999Z"
																			fill="#94A3B8"
																		/>
																	</svg>
																</div>
																<div
																	class="
																		px-6
																		py-4
																		whitespace-nowrap
																		text-neutral-700
																		text-center
																		w-16
																	"
																>
																	{{ i + 1 }}
																</div>
																<div
																	class="
																		px-6
																		py-4
																		w-16
																	"
																>
																	<svg
																		width="20"
																		height="14"
																		viewBox="0 0 20 14"
																		fill="none"
																		xmlns="http://www.w3.org/2000/svg"
																	>
																		<path
																			d="M0.955408 1.76172H19.0476M0.952393 6.99981H19.0401M0.955408 12.2379H19.0401"
																			stroke="#94A3B8"
																			stroke-width="2"
																			stroke-linecap="round"
																			stroke-linejoin="round"
																		/>
																	</svg>
																</div>
																<div
																	class="
																		px-6
																		py-4
																		text-neutral-700
																	"
																	style="
																		width: calc(
																			100% -
																				16rem
																		);
																	"
																>
																	<div
																		class="
																			flex
																			items-center
																			hover:text-yellow-700
																			hover:underline
																		"
																		@click="
																			$router.push(
																				`/goal/${studentId}/${
																					goal.goal_id ||
																					goal.id
																				}`
																			)
																		"
																		v-html="
																			goal.name
																		"
																	></div>
																</div>
																<div
																	class="
																		w-16
																		flex
																		justify-center
																	"
																>
																	<Checkbox
																		:value="
																			isSelected(
																				goal
																			)
																		"
																		@click.stop="
																			onSelect(
																				goal
																			)
																		"
																	/>
																</div>
															</div>
														</div>
													</transition-group>
												</draggable>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				class="
					flex flex-col
					items-end
					justify-end
					bottom-0
					right-0
					w-full
					gap-4
				"
			>
				<button
					:disabled="!name || !selected.length"
					class="button-p text-center whitespace-nowrap w-56"
					type="button"
					@click="submit"
				>
					Xác nhận
				</button>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import { $api } from '@/services';
import Checkbox from '@/components/elements/checkbox/Checkbox';
import DaIcon from '@/components/elements/daIcon/DaIcon';
import Multiselect from '@suadelabs/vue3-multiselect';
import { toEnglish } from '@/helper/toEnglish';
import GoalListFull from '@/components/elements/GoalListFull.vue';
import GroupDetailFull from '@/components/modules/groups/GroupDetailfull.vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { VueDraggableNext } from 'vue-draggable-next';

const draggable = VueDraggableNext;
const route = useRoute();
const router = useRouter();
const store = useStore();
const name = ref('');
const errorName = ref('');
const searchQuery = ref('');
const isLoading = ref(false);
const goals = ref([]);
const selected = ref([]);
const skillsData = ref([]);
const showModal = ref(false);
const skillSelected = ref('');
const timeOut = ref(null);

const studentId = computed(() => {
	return route.query?.student_id;
});
const goalId = computed(() => {
	return route.query?.goal_id;
});

onMounted(() => {
	if (route.query.goal_id) fetchGoal();
	else
		fetchPlans({
			reject_goal_has_parent: true,
		});
});

watch(skillSelected, (newValue, oldValue) => {
	if (newValue.id === oldValue.id) return;
	if (!oldValue.id) return;
	searchQuery.value = '';
	fetchPlans({
		reject_goal_has_parent: true,
		skill_id: skillSelected.value.id,
	});
});

const fetchPlans = async (payload) => {
	try {
		const res = await $api.goal.getListGroupWithDetailInLibs(
			studentId.value,
			payload || {}
		);
		goals.value = res.data.data;
		skillsData.value = res.data.skills;
		if (!skillSelected.value) skillSelected.value = skillsData.value[0];
		isLoading.value = false;
	} catch (e) {
		console.log(e);
	}
};

const onSelect = (goal) => {
	const index = selected.value.findIndex((s) => s?.id === goal.id);
	if (index !== -1) {
		selected.value = selected.value.filter((s) => s.id !== goal.id);
		if (goalId.value) goals.value.push(goal);
	} else {
		selected.value.push(goal);
	}
};

const isSelected = (goal) => {
	const index = selected.value.findIndex((s) => s.id === goal.id);
	if (index === -1) {
		return false;
	} else return true;
};

const log = () => {
	console.log('test');
};

const submit = async () => {
	try {
		let subGoal = selected.value.map((i) => i.id);
		const res = await $api.goal.createGoalLib(studentId.value, {
			goal: {
				is_parent: true,
				name: name.value,
				skill_id: skillSelected.value.id,
				sub_goal_ids: subGoal,
				state: 'library',
			},
		});
		if (res.data.data) {
			store.dispatch('setAlertMessage', {
				message: 'Đã tạo thành công mục tiêu chung',
			});
			setTimeout(
				() => router.push(`/plans/${studentId.value}/goal-bank`),
				500
			);
		}
	} catch (e) {
		if (e?.response?.data?.errors_by_field['name']) {
			errorName.value =
				'Tên nhóm mục tiêu không được trùng với tên nhóm đã có trước đó';
			setTimeout(() => {
				errorName.value = '';
			}, 3000);
		} else {
			store.dispatch('setAlertMessage', {
				message:
					e?.response?.data?.errors?.join('<br/>') ||
					'Đã có lỗi xảy ra. Vui lòng thử lại',
				type: 'danger',
			});
		}
	}
};

const searching = () => {
	clearInterval(timeOut.value);
	timeOut.value = setTimeout(() => {
		fetchPlans({
			name: searchQuery.value,
			reject_goal_has_parent: true,
			skill_id: skillSelected.value.id,
		});
	}, 300);
};

const fetchGoal = async () => {
	try {
		const res = await $api.goal.goalDetail(goalId.value, studentId.value);
		if (res.data.data) {
			let data = res.data.data;
			selected.value = data.sub_goals;
			name.value = data.name;
			skillSelected.value = data.skill;
			fetchPlans({
				reject_goal_has_parent: false,
				skill_id: skillSelected.value.id,
			});
		}
	} catch (e) {
		console.log(e);
	}
};
</script>
